<template>
  <div>
    <v-autocomplete
      class="pt-2"
      v-model="storedPositions"
      :items="state.positions"
      item-text="name"
      item-value="id"
      :search-input.sync="state.searchText"
      v-on:keyup="debouncedGetData"
      clearable
      dense
      hide-details
      multiple
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="GAM Positions"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "PositionFilter";

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();
    const { axiosGet } = useAxios();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const state = reactive({
      searchText: "",
      positions: [],
    });

    // =>  METHODS
    const debouncedGetData = debounce(async () => {
      await getData();
    }, 500);

    const getData = async () => {
      const { data } = await axiosGet("/gam/positions", {
        search_value: state.searchText,
      });

      set(state, "positions", data);
    };

    // =>  COMPUTED
    const storedPositions = computed({
      get: () => store.getters["regie/getGamPositions"],
      set(e) {
        store.dispatch("regie/updateGamPositions", e);
      },
    });

    // =>  WATCH
    watch(storedPositions, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        is_multiple: true,
        is_integer: true,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await getData();

      await synchronizeFilterWithQueryString({
        dispatcher: "regie/updateGamPositions",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: storedPositions.value,
        is_multiple: true,
        is_integer: true,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      state,
      storedPositions,
      debouncedGetData,
    };
  },
  async created() {},
};
</script>

<style></style>
